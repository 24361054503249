<template>
  <div class="forgot-password-page">
    <CardPanel :isReady="isReady">
      <template v-slot:messages>
        <TextBase>{{ translations.labels.forgotPassword_infoText }}</TextBase>
        <TextBase v-if="showMessage" type="success">{{
          translations.success.email_sent
        }}</TextBase>
        <TextBase v-if="showError" type="error">{{
          translations.errors.somethingWrong
        }}</TextBase>
      </template>

      <template v-slot:inputs>
        <input
          v-model="user.email"
          class="input-text email"
          type="text"
          :placeholder="translations.labels.email"
          id="email"
        />
      </template>

      <template v-slot:links>
        <Button-base
          :isFullWidth="true"
          :text="translations.labels.button_send"
          :onClick="forgotPassword"
          :type="1"
          center="center"
          :bigger="true"
        />

        <LinkBase
          :centered="true"
          :text="translations.labels.login"
          link="/login"
        />
      </template>
    </CardPanel>
  </div>
</template>

<script>
import ButtonBase from "@/components/dom/ButtonBase";
import CardPanel from "@/components/panel/CardPanel";
import LinkBase from "@/components/dom/LinkBase";
import Loader from "@/components/dom/Loader";
import TextBase from "@/components/dom/TextBase";
import Component from "vue-class-component";

@Component({
  components: {
    ButtonBase,
    CardPanel,
    LinkBase,
    Loader,
    TextBase,
  },
  inject: {
    authService: "authService",
  },
})
export default class ForgotPassword {
  isReady = true;
  showMessage = false;
  showError = false;
  user = {
    email: "",
  };
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }
  async forgotPassword() {
    this.isReady = false;
    try {
      await this.authService.forgotPassword(this.user);
      this.showMessage = true;
      this.showError = false;
    } catch (err) {
      this.showMessage = false;
      this.showError = true;
    }
    this.isReady = true;
  }
}
</script>
<style lang="scss" scoped>
.forgot-password-page {
  background-color: $grey-light;
  width: 100%;
  height: 100%;

  .wrp-button {
    margin-top: 25px;
  }
}
</style>
